<template>
    <section class="mr-3">
        <div class="row mx-0 py-3 mt-3 align-items-center" :class="$route.name == 'admin.delivery.lista.lista' ? 'bg-white br-t-8': '' ">
            <div class="col-auto d-middle">
                <span class="text-general f-500 f-18">
                    Lista de deliverys
                </span>
                <div class="bg-general3 mx-2 px-3 py-1 text-white br-20">
                    {{ cantidad }}
                </div>
            </div>
            <div class="col-auto ml-auto">
                <div v-if="$can('boton_delivery_crear')" class="btn-general text-white cr-pointer px-5 py-2 br-8 f-14" @click="crearDelivery">
                    Crear Delivery
                </div>
            </div>
            <div class="col-3">
                <el-input v-model="buscar" placeholder="Buscar Delivery" size="small" autocomplete="off" class="br-20 w-100" />
            </div>
            <div class="col-auto">
                <div class="border-black br-12 d-middle display-mode">
                    <router-link :to="{name: 'admin.delivery.lista.mosaico'}">
                        <el-tooltip placement="bottom" content="Ver como mosaico" effect="light">
                            <div class="br-l-8 h-100 d-middle-center w-100" :class="$route.name == 'admin.delivery.lista.mosaico' ? 'bg-general3 text-white' : 'text-general'">
                                <i class="icon-view-grid" />
                            </div>
                        </el-tooltip>
                    </router-link>
                    <router-link :to="{name: 'admin.delivery.lista.lista'}">
                        <el-tooltip placement="bottom" content="Ver como vista" effect="light">
                            <div class="br-r-8 h-100 d-middle-center w-100" :class="$route.name == 'admin.delivery.lista.lista' ? 'bg-general3 text-white' : 'text-general'">
                                <i class="icon-menu" />
                            </div>
                        </el-tooltip>
                    </router-link>
                </div>
            </div>
        </div>
        <router-view ref="vistaDeliverys" :buscar="buscar" />
        <!-- Partials -->
        <modal-crear-delivery ref="modalCrearDelivery" @actualizar="listar" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    components: {
        modalCrearDelivery: () => import ('./partials/modalCrearDelivery')
    },
    data(){
        return {
            buscar: ''
        }
    },
    computed: {
        ...mapGetters({
            cantidad:'delivery/cantidad',
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
    },
    watch: {
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.buscar = ''
        },
        id_cedis(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.buscar = ''
        },
    },
    methods: {
        crearDelivery(){
            this.$refs.modalCrearDelivery.toggle();
        },
        listar(){
            this.$refs.vistaDeliverys.montar();
        }

    }
}
</script>
<style lang="scss" scoped>
.br-12{border-radius: 12px !important;}
.br-l-12{
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
}
.br-r-12{
    border-top-right-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
.active{
    background-color: var(--text-general);
    color: #ffffff;
}
.display-mode{
    a{
        height: 32px;
        width: 32px;
    }
}
</style>
